<template>
  <Card :image="image" :href="$link.create(url)">
    <template slot="content">
      <strong>{{ title }}</strong>
    </template>
  </Card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import { ThemeCardProps } from "./Props";

export default Vue.extend({
  name: "ThemeCard",
  components: { Card },
  props: {
    title: {
      type: String as PropType<ThemeCardProps["title"]>,
      required: true,
    },
    url: {
      type: String as PropType<ThemeCardProps["url"]>,
      required: false,
      default: null,
    },
    image: {
      type: Object as PropType<ThemeCardProps["image"]>,
      required: false,
      default() {
        return {};
      },
    },
  },
});
</script>
