<template>
  <Alert :type="type">
    <template #content
      ><AppLink v-if="heading" class="travel-alert__heading" :to="computedLink"
        ><span>{{ heading }}</span></AppLink
      >
      &nbsp;{{ content }}</template
    >
  </Alert>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Alert from "atlas/src/components/Alert/Alert.vue";
import AppLink from "atlas/src/components/AppLink/AppLink.vue";
import { TravelAlertProps } from "./Props";
import { createLink } from "~/lib/utils/link/createLink";

export default Vue.extend({
  name: "TravelAlert",
  components: {
    Alert,
    AppLink,
  },
  props: {
    heading: {
      type: String as PropType<TravelAlertProps["heading"]>,
      required: false,
      default: undefined,
    },
    content: {
      type: String as PropType<TravelAlertProps["content"]>,
      required: false,
      default: undefined,
    },
    type: {
      type: String as PropType<TravelAlertProps["type"]>,
      required: false,
      default: undefined,
    },
  },
  computed: {
    computedLink() {
      return createLink(this.$i18n.locale)("/travel-alerts");
    },
  },
});
</script>

<style lang="scss">
@import "./travel-alert";
</style>
