<template>
  <div class="l-grid__cell l-grid__cell--4-col">
    <Tile
      :title="title"
      :icon="icon"
      class="u-height--full tile tile--x-large unique-selling-point__tile"
    >
      <template v-if="descriptionType === 'object'">
        <JsonRichText v-bind="description" />
      </template>
      <p v-else>
        {{ description }}
      </p>
    </Tile>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import { UniqueSellingPointProps } from "./Props";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "UniqueSellingPoint",
  components: {
    Tile,
    JsonRichText,
  },
  props: {
    icon: {
      type: String as PropType<UniqueSellingPointProps["icon"]>,
      required: true,
    },
    title: {
      type: String as PropType<UniqueSellingPointProps["title"]>,
      required: true,
    },
    description: {
      type: [Object, String] as PropType<
        UniqueSellingPointProps["description"]
      >,
      required: false,
      default: undefined,
    },
  },
  computed: {
    descriptionType() {
      return typeof this.description;
    },
  },
});
</script>
