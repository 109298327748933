<template>
  <CarouselStatic v-if="themes?.length" :description="description">
    <CarouselStaticItem v-for="(theme, index) in themes" :key="index">
      <ThemeCard v-bind="theme" />
    </CarouselStaticItem>
    <CarouselStaticItem
      v-if="hasLastCarouselItemSlot"
      class="card u-flex-direction--row"
    >
      <slot name="lastCarouselItem" />
    </CarouselStaticItem>
  </CarouselStatic>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import CarouselStatic from "atlas/src/components/CarouselStatic/CarouselStatic.vue";
import CarouselStaticItem from "atlas/src/components/CarouselStatic/CarouselStaticItem.vue";
import { ThemeCarouselProps } from "./Props";
import ThemeCard from "~/components/ThemeCard/ThemeCard.vue";

export default Vue.extend({
  name: "ThemeCarousel",
  components: {
    ThemeCard,
    CarouselStatic,
    CarouselStaticItem,
  },
  props: {
    description: {
      type: String as PropType<ThemeCarouselProps["description"]>,
      required: true,
    },
    themes: {
      type: Array as PropType<ThemeCarouselProps["themes"]>,
      required: true,
    },
  },
  computed: {
    hasLastCarouselItemSlot() {
      return !!this.$slots.lastCarouselItem;
    },
  },
});
</script>
