<template>
  <div class="u-margin-top--4 sm:u-margin-top--6" data-cy="home-themes">
    <h2 class="u-margin-top--0">{{ title }}</h2>
    <ThemeCarousel :themes="themes" :description="title">
      <template #lastCarouselItem>
        <Button
          :href="$link.create('/theme')"
          class="button--unboxed button--block"
          aria-label="See all themes"
        >
          <strong class="u-padding-right--0-5">See all themes</strong>
          <Icon name="arrow-right" class="icon--size-1-5" />
        </Button>
      </template>
    </ThemeCarousel>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import "atlas/src/components/Icon/icons/arrow-right.js";
import { HomeThemeProps } from "./Props";
import ThemeCarousel from "~/components/ThemeCarousel/ThemeCarousel.vue";

export default Vue.extend({
  name: "HomeThemes",
  components: {
    Button,
    Icon,
    ThemeCarousel,
  },
  props: {
    title: {
      type: String as PropType<HomeThemeProps["title"]>,
      required: true,
    },
    themes: {
      type: Array as PropType<HomeThemeProps["themes"]>,
      required: true,
    },
  },
});
</script>
