<template>
  <div
    v-if="tagline || shortDescription"
    class="
      u-margin-top--3
      u-margin-bottom--3
      md:u-margin-top--6 md:u-margin-bottom--6
    "
    data-cy="home-introduction"
  >
    <h2
      v-if="tagline"
      class="headline--3 u-text-align--center"
      data-cy="home-introduction__tagline"
    >
      {{ tagline }}
    </h2>

    <JsonRichText
      v-if="shortDescription"
      class="u-text-align--center u-padding-bottom--0-5 u-margin-bottom--0"
      data-cy="home-introduction__short-description"
      v-bind="shortDescription"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { HomeIntroductionProps } from "./Props";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "HomeIntroduction",
  components: {
    JsonRichText,
  },
  props: {
    shortDescription: {
      type: Object as PropType<HomeIntroductionProps["shortDescription"]>,
      required: false,
      default: null,
    },
    tagline: {
      type: String as PropType<HomeIntroductionProps["tagline"]>,
      required: false,
      default: null,
    },
  },
});
</script>
