<template>
  <div class="l-grid" data-cy="unique-selling-point-list">
    <UniqueSellingPoint
      v-for="(uniqueSellingPoint, index) in uniqueSellingPoints"
      v-bind="uniqueSellingPoint"
      :key="index"
      data-cy="unique-selling-point"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import UniqueSellingPoint from "../UniqueSellingPoint/UniqueSellingPoint.vue";
import { UniqueSellingPointProps } from "../UniqueSellingPoint/Props";

export default Vue.extend({
  name: "UniqueSellingPointList",
  components: {
    UniqueSellingPoint,
  },
  props: {
    uniqueSellingPoints: {
      type: Array as PropType<UniqueSellingPointProps[]>,
      required: true,
    },
  },
});
</script>
