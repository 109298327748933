<template>
  <AtlasSpotlight
    :tagline="tagline"
    :description="description"
    :primary-icon="primaryIcon"
    :secondary-icon="secondaryIcon"
    :cta="ctaWithRegionalizedUrl"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AtlasSpotlight from "atlas/src/components/Spotlight/Spotlight.vue";
import { PurposeSpotlightProps } from "./Props";

export default Vue.extend({
  name: "PurposeSpotlight",
  components: {
    AtlasSpotlight,
  },
  props: {
    tagline: {
      type: String as PropType<PurposeSpotlightProps["tagline"]>,
      required: true,
    },
    description: {
      type: String as PropType<PurposeSpotlightProps["description"]>,
      required: true,
    },
    primaryIcon: {
      type: Object as PropType<PurposeSpotlightProps["primaryIcon"]>,
      required: true,
    },
    secondaryIcon: {
      type: Object as PropType<PurposeSpotlightProps["secondaryIcon"]>,
      required: false,
      default: undefined,
    },
    cta: {
      type: Object as PropType<PurposeSpotlightProps["cta"]>,
      required: true,
    },
  },
  computed: {
    ctaWithRegionalizedUrl() {
      const ctaWithRegionalizedUrl = this.cta;
      ctaWithRegionalizedUrl.url = this.$link.create(
        ctaWithRegionalizedUrl.url
      );
      return ctaWithRegionalizedUrl;
    },
  },
});
</script>
