<template>
  <div>
    <Banner
      :lazy-src="homeBanner.banner.src"
      :lazy-srcset="homeBanner.banner.srcSet"
      :alt="homeBanner.banner.alt"
      scale-xs="8:11"
      scale-sm="4:3"
      scale-md="7:3"
      scale-lg="7:3"
      scale-xl="7:3"
    >
      <div v-if="homeBanner.title" class="l-container u-height--full">
        <div class="home-banner">
          <div class="home-banner__content">
            <h1 class="home-banner__title" data-cy="home-banner-title">
              {{ homeBanner.title }}
            </h1>
            <client-only><FindTrip /></client-only>
          </div>
        </div>
      </div>
    </Banner>
    <ErrorBoundary>
      <Notification
        v-if="notification"
        v-bind="notification"
        :class="notificationClass"
      />
    </ErrorBoundary>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Banner from "atlas/src/components/Banner/Banner.vue";
import FindTrip from "../FindTrip/FindTrip.vue";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary.vue";
import { HomeBannerProps } from "./Props";
import Notification from "~/components/Notification/Notification.vue";

export default Vue.extend({
  name: "HeaderBanner",
  components: {
    Banner,
    FindTrip,
    Notification,
    ErrorBoundary,
  },
  props: {
    homeBanner: {
      type: Object as PropType<HomeBannerProps["homeBanner"]>,
      required: true,
    },
  },
  data() {
    return {
      notificationClass: "notification--text-hidden",
    };
  },
  computed: {
    notification() {
      return this.$store.getters["header/getNotification"];
    },
  },
  created() {
    if (process.client) {
      this.notificationClass = "";
    }
  },
});
</script>

<style lang="scss">
@import "../../components/HomeBanner/home-banner";
</style>
