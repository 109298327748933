<template>
  <div v-if="blogPosts && blogPosts.length > 0">
    <h2 v-if="carouselTitle" data-cy="blog-carousel__title">
      {{ carouselTitle }}
    </h2>
    <CarouselStatic
      :description="carouselTitle ?? 'Articles'"
      data-cy="static-carousel"
    >
      <CarouselStaticItem v-for="(blog, index) of blogPosts" :key="index">
        <BlogCard
          :title="blog.title"
          :image="blog.image"
          :createdDate="new Date(blog.created)"
          :url="blog.link"
        />
      </CarouselStaticItem>
    </CarouselStatic>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import CarouselStatic from "atlas/src/components/CarouselStatic/CarouselStatic.vue";
import CarouselStaticItem from "atlas/src/components/CarouselStatic/CarouselStaticItem.vue";
import BlogCard from "atlas/src/components/BlogCard/BlogCard.vue";
import { BlogCarouselProps } from "./Props";

export default Vue.extend({
  name: "BlogCarousel",
  components: {
    CarouselStatic,
    CarouselStaticItem,
    BlogCard,
  },
  props: {
    carouselTitle: {
      type: String as PropType<BlogCarouselProps["carouselTitle"]>,
      required: false,
      default: undefined,
    },
    blogPosts: {
      type: Array as PropType<BlogCarouselProps["blogPosts"]>,
      required: false,
      default() {
        return [];
      },
    },
  },
});
</script>
