<template>
  <Page v-bind="page">
    <template #alerts>
      <ErrorBoundary
        v-if="travelAlert"
        class="l-container home-page__alert-container"
      >
        <TravelAlert v-bind="travelAlert" />
      </ErrorBoundary>
    </template>
    <ErrorBoundary>
      <HomeBanner data-cy="home-banner" v-bind="homeBanner" />
    </ErrorBoundary>
    <div class="l-container u-margin-top--2">
      <ErrorBoundary>
        <HomeIntroduction v-bind="homeIntroduction" />
      </ErrorBoundary>
      <ErrorBoundary v-if="homeThemes?.themes?.length">
        <HomeThemes v-bind="homeThemes" />
      </ErrorBoundary>
      <ErrorBoundary>
        <HomeTrips
          v-bind="homeTrips"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <HomePromotion
          v-bind="homePromotion"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
    </div>

    <ErrorBoundary
      v-if="homePurpose && validateObject(homePurpose)"
      class="u-background-color--sand"
      data-cy="home-purpose"
    >
      <PurposeSpotlight
        v-bind="homePurpose"
        class="l-container u-margin-top--4 sm:u-margin-top--6"
      />
    </ErrorBoundary>
    <ErrorBoundary v-if="homeBlogs && validateObject(homeBlogs.blogPosts)">
      <BlogCarousel
        v-bind="homeBlogs"
        class="l-container u-margin-top--4 sm:u-margin-top--6"
        data-cy="home-blogs"
        carouselTitle="Get inspired on The Good Times"
      />
    </ErrorBoundary>
    <ErrorBoundary v-if="homeUSPs?.usps?.length">
      <HomeUSPs
        v-bind="homeUSPs"
        class="l-container u-margin-top--4 sm:u-margin-top--6"
      />
    </ErrorBoundary>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { HomePageProps } from "./Props";
import HomeUSPs from "~/components/HomeUSPs/HomeUSPs.vue";
import HomePromotion from "~/components/HomePromotion/HomePromotion.vue";
import HomeBanner from "~/components/HomeBanner/HomeBanner.vue";
import HomeIntroduction from "~/components/HomeIntroduction/HomeIntroduction.vue";
import HomeThemes from "~/components/HomeThemes/HomeThemes.vue";
import HomeTrips from "~/components/HomeTrips/HomeTrips.vue";
import Page from "~/components/Page/Page.vue";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary.vue";
import TravelAlert from "~/components/TravelAlert/TravelAlert.vue";
import { isEmpty } from "~/lib/utils/isEmpty";
import { BlogCarouselProps } from "~~/components/BlogCarousel/Props";
import BlogCarousel from "~/components/BlogCarousel/BlogCarousel.vue";
import PurposeSpotlight from "~/components/PurposeSpotlight/PurposeSpotlight.vue";

export default Vue.extend({
  name: "HomePage",
  components: {
    HomeThemes,
    HomeBanner,
    HomeIntroduction,
    HomePromotion,
    PurposeSpotlight,
    HomeTrips,
    HomeUSPs,
    BlogCarousel,
    Page,
    TravelAlert,
    ErrorBoundary,
  },
  props: {
    homeBanner: {
      type: Object as PropType<HomePageProps["homeBanner"]>,
      required: true,
    },
    homeIntroduction: {
      type: Object as PropType<HomePageProps["homeIntroduction"]>,
      required: true,
    },
    homeThemes: {
      type: Object as PropType<HomePageProps["homeThemes"]>,
      required: true,
    },
    homeTrips: {
      type: Object as PropType<HomePageProps["homeTrips"]>,
      required: true,
    },
    homePromotion: {
      type: Object as PropType<HomePageProps["homePromotion"]>,
      required: true,
    },
    homePurpose: {
      type: Object as PropType<HomePageProps["homePurpose"]>,
      required: true,
    },
    homeBlogs: {
      type: Object as PropType<HomePageProps["homeBlogs"]>,
      required: true,
    },
    homeUSPs: {
      type: Object as PropType<HomePageProps["homeUSPs"]>,
      required: true,
    },
    page: {
      type: Object as PropType<HomePageProps["page"]>,
      required: true,
    },
    travelAlert: {
      type: Object as PropType<HomePageProps["travelAlert"]>,
      required: false,
      default: undefined,
    },
  },
  methods: {
    validateObject(
      objectData:
        | HomePageProps["homePurpose"]
        | BlogCarouselProps["blogPosts"]
        | undefined
    ) {
      return !isEmpty(objectData);
    },
  },
});
</script>

<style lang="scss">
@import "./home-page";
</style>
