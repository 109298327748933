<template>
  <div v-if="usps?.length" data-cy="home-usps">
    <h2 v-if="title" class="u-margin-top--0">
      {{ title }}
    </h2>
    <UniqueSellingPointList :uniqueSellingPoints="usps" />
    <div class="l-grid u-justify-content--center">
      <div
        class="
          l-grid__cell
          l-grid__cell--12-col
          l-grid__cell--5-col-tablet
          l-grid__cell--4-col-desktop
          u-margin-top--1-5
        "
      >
        <Button
          v-if="ctaUrl && ctaTitle"
          :href="$link.create(ctaUrl)"
          class="button--secondary button--block"
          data-cy="cta-button"
        >
          {{ ctaTitle }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import { HomeUSPsProps } from "./Props";
import UniqueSellingPointList from "~/components/UniqueSellingPointList/UniqueSellingPointList.vue";

export default Vue.extend({
  name: "HomeUSPs",
  components: {
    Button,
    UniqueSellingPointList,
  },
  props: {
    title: {
      type: String as PropType<HomeUSPsProps["title"]>,
      default: null,
    },
    ctaTitle: {
      type: String as PropType<HomeUSPsProps["ctaTitle"]>,
      default: null,
    },
    ctaUrl: {
      type: String as PropType<HomeUSPsProps["ctaUrl"]>,
      default: null,
    },
    usps: {
      type: Array as PropType<HomeUSPsProps["usps"]>,
      default: null,
    },
  },
});
</script>
