<template>
  <div v-if="trips.length" data-cy="home-trips">
    <h2 v-if="title" class="u-margin-top--0">{{ title }}</h2>
    <TripCarousel :trips="trips">
      <template #lastCarouselItem>
        <Button
          :href="$link.create('/search')"
          class="button--unboxed button--block"
          aria-label="see all trips"
        >
          <strong class="u-padding-right--0-5">See all trips</strong>
          <Icon name="arrow-right" class="icon--size-1-5" />
        </Button>
      </template>
    </TripCarousel>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import { HomeTripsProps } from "./Props";
import TripCarousel from "~/components/TripCarousel/TripCarousel.vue";
import "atlas/src/components/Icon/icons/arrow-right.js";

export default Vue.extend({
  name: "HomeTrips",
  components: {
    TripCarousel,
    Button,
    Icon,
  },
  props: {
    title: {
      type: String as PropType<HomeTripsProps["title"]>,
      required: true,
    },
    trips: {
      type: Array as PropType<HomeTripsProps["trips"]>,
      required: true,
    },
  },
});
</script>
