<template>
  <Card
    v-if="title && image"
    :image="image"
    data-cy="home-promotion"
    class="card--horizontal"
  >
    <template slot="content">
      <div
        class="
          u-display--flex
          u-flex-direction--column
          u-height--full
          u-padding-left--0-5
          u-padding-right--0-5
          sm:u-padding-left--0 sm:u-padding-right--0
        "
      >
        <h3
          v-if="title"
          data-cy="home-promotion__title"
          class="u-margin-bottom--1 u-margin-top--0"
        >
          {{ title }}
        </h3>
        <JsonRichText
          v-if="description"
          data-cy="home-promotion__description"
          class="lead u-margin-top--0 u-margin-bottom--0"
          v-bind="description"
        />
        <div class="u-margin-top--auto">
          <Button
            v-if="url && cta"
            :href="$link.create(url)"
            data-cy="home-promotion__cta"
            class="button--secondary u-display--flex sm:u-display--inline-flex"
          >
            {{ cta }}
          </Button>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Card from "atlas/src/components/Card/Card.vue";
import { HomePromotionProps } from "./Props";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "HomePromotion",
  components: {
    Button,
    JsonRichText,
    Card,
  },
  props: {
    title: {
      type: String as PropType<HomePromotionProps["title"]>,
      required: false,
      default: null,
    },
    description: {
      type: Object as PropType<HomePromotionProps["description"]>,
      required: false,
      default: null,
    },
    image: {
      type: Object as PropType<HomePromotionProps["image"]>,
      required: false,
      default: null,
    },
    cta: {
      type: String as PropType<HomePromotionProps["cta"]>,
      required: false,
      default: null,
    },
    url: {
      type: String as PropType<HomePromotionProps["url"]>,
      required: false,
      default: null,
    },
  },
});
</script>
