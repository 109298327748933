<template>
  <div v-if="trips?.length" class="u-margin-bottom--4">
    <CarouselStatic description="trip-carousel">
      <CarouselStaticItem v-for="(trip, index) in trips" :key="index">
        <TripCard v-bind="trip" type="mini" />
      </CarouselStaticItem>
      <CarouselStaticItem
        v-if="hasLastCarouselItemSlot"
        class="card u-flex-direction--row"
      >
        <slot name="lastCarouselItem" />
      </CarouselStaticItem>
    </CarouselStatic>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import CarouselStatic from "atlas/src/components/CarouselStatic/CarouselStatic.vue";
import CarouselStaticItem from "atlas/src/components/CarouselStatic/CarouselStaticItem.vue";
import { TripCarouselProps } from "./Props";
import TripCard from "~/components/TripCard/TripCard.vue";

export default Vue.extend({
  name: "TripCarousel",
  components: {
    CarouselStatic,
    CarouselStaticItem,
    TripCard,
  },
  props: {
    trips: {
      type: Array as PropType<TripCarouselProps["trips"]>,
      default: undefined,
    },
  },
  computed: {
    hasLastCarouselItemSlot() {
      return !!this.$slots.lastCarouselItem;
    },
  },
});
</script>
